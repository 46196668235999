<template>
  <div class="single-bridge-common">
    <div class="tit-search flex space-between">
      <div class="mb-2">
        <el-button class="btn-primary" @click="goto()">新增</el-button>
      </div>
      <c-search
          v-model="pageParams"
          :searchItems="searchItems"
          @search="handleSearch"
          @reset="handleReset"
      ></c-search>
    </div>
    <div class="table-wrapper">
      <c-table
        :index="true"
        :data="pageData"
        :tableColumns="tableColumns"
        v-loading="pageLoading"
        :stripe="true"
        :pagination="pageParams"
        @change="pageGet"
      >
        <template v-slot:custom="{ row }">
          <span class="opt-edit mr-6" @click="goto(row)">编辑</span>
          <span class="opt-edit mr-6" @click="download(row)" v-if="row.fileUrl">下载附件</span>
          <span class="opt-delete" @click="delFile(row.id)" v-if="row.fileUrl" style="margin-right: 1.5rem">删除附件</span>
          <span class="opt-delete" @click="pageDel(row.id)">删除</span>
        </template>
      </c-table>
    </div>

    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      width="900px"
      :visible.sync="dialogVisible"
      :before-close="beforeDialogClose"
    >
      <div class="form-wrapper manage-scroll">
        <el-form ref="form" :model="form.model" :rules="form.rules" label-width="100px">
          <el-row :gutter="20" class="w-full">
            <el-col :span="12">
              <el-form-item label="标题" prop="title">
                <el-input v-model="form.model.title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发生时间" prop="occurTime">
                <el-date-picker
                    v-model="form.model.occurTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="选择日期时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="内容" prop="content">
                <el-input type="textarea" v-model="form.model.content"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="文件上传" prop="showTag">
                <el-upload
                    class="upload-demo"
                    :action="url"
                    :headers="headers"
                    :multiple="false"
                    :show-file-list="false"
                    :on-success="uploadSuccess"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip" v-if="form.model.fileName">
                    <i class="el-icon-tickets" style="font-size: 16px;padding-right: 5px"></i>
                    <span style="font-size: 14px">{{form.model.fileName}}</span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFields">取 消</el-button>
        <el-button class="btn-primary" @click="submit('form')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MPage from "@/mixins/MPage.js";
import Configs from "./config/index";
import common from "../../../../../assets/js/common"
let mPage = MPage({
  pagePath: "/important/event/listPage",
  pageDelPath: "/important/event/remove",
  pageDelsPath: "",
  pageParams: {},
});
export default {
  mixins: [mPage],
  data() {
    let { searchItems,tableColumns } = Configs(this);
    return {
      searchItems,
      tableColumns,
      dialogVisible: false,
      title: "",
      pageData: [],
      url:localStorage.getItem("baseUrl") + '/important/event/file/upload',
      headers:{
        token:localStorage.getItem("bridge-token")
      },
      form:{
        model:{
          fileName:'',
          fileUrl:'',
          title:'',
          occurTime:'',
          content:''
        },
        rules:{
          title:[
            { required: true, message: '请填写标题', trigger: 'blur' }
          ],
          occurTime:[
            { required: true, message: '请填写发生时间', trigger: 'blur' }
          ],
          content:[
            { required: true, message: '请填写内容', trigger: 'blur' }
          ]
        }
      },
      api:'',
      isAdd:true
    };
  },
  methods: {
    goto(data) {
      if (data) {
        this.api = '/important/event/update'
        this.title = "编辑事件";
        // this.gotoDetail(data)
        this.form.model = Object.assign({}, data);
      } else {
        this.api = '/important/event/save'
        this.title = "新增事件";
      }
      this.dialogVisible = true;
    },
    beforeDialogClose(done) {
      done();
    },
    delFile(id){
      this.$http.get('/important/event/file/remove',{params:{id:id}}).then(res => {
        if (res.success){
          this.$message.success('删除成功')
          this.pageGet(true)
        }
      })
    },
    uploadSuccess(e){
      if (e.success){
        this.form.model.fileName = e.data.name
        this.form.model.fileUrl = e.data.url
      }
    },
    download(data){
      this.$http.get('/important/event/file/download', {
        contentType:"application/x-www-form-urlencoded",
        responseType: "blob",
        params: {id:data.id}
      }).then(res => {
        common.blobDownLoad(res)
      })
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post(this.api,this.form.model).then(res=>{
            if(res.success){
              this.$message.success("操作成功");
              this.pageGet()
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg)
            }
          });
        }
      })
    },
    resetFields() {
      this.form.model = {
        fileName:'',
        fileUrl:'',
        title:'',
        occurTime:'',
        content:''
      }
      this.dialogVisible = false;
    },
    handleReset() {
      this.pageParams.title = ''
      this.pageParams.startTime = ''
      this.pageParams.endTime = ''
      this.pageGet(true)
    },
    handleSearch() {
      if (this.pageParams.date){
        this.pageParams.startTime = this.pageParams.date[0]
        this.pageParams.endTime = this.pageParams.date[1]
      }
      this.pageGet(true)
    },
  },
};
</script>
<style scoped>
  /deep/ .el-date-editor.el-input{
    width: 100%;
  }
  .upload-demo{
    display: flex;
    align-items: center;
  }
  .upload-demo .el-button{
    margin-right: 10px;
  }
</style>
