export default function Configs() {
    const searchItems = [
        {
            label: "标题",
            span: 8,
            model: "title",
            clearable: true,
            type: "input",
        },
        {
            label: "时间",
            span:16,
            type: "datetimerange",
            model: "date",
            clearable: true,
        },
    ];
    const tableColumns = [
        {
            label: "事件名称",
            prop: "title",
            align: "center",
            minWidth: 140,
            show: true
        },
        {
            label: "发生时间",
            prop: "occurTime",
            align: "center",
            show: true
        },
        {
            label: "操作",
            name: 'custom',
            align: "center",
            show: true
        },
    ];
    return {
        searchItems,
        tableColumns
    };
}
